import { Routes } from '@angular/router';
import { ErrorComponent } from '@app-shared';
import { ProducerLogonComponent } from './producer-logon/producer-logon.component';

export const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: ProducerLogonComponent },
    {
        path: 'forgot-password',
        title: 'Forgot Password',
        loadComponent: () => import('./forgot-password/forgot-password.component').then((c) => c.ForgotPasswordComponent),
    },
    {
        path: 'reset-password',
        title: 'Reset Password',
        loadComponent: () => import('./reset-password/reset-password.component').then((c) => c.ResetPasswordComponent),
    },
    {
        path: 'change-password/:loginId',
        title: 'Change Password',
        loadComponent: () => import('./change-password/change-password.component').then((c) => c.ChangePasswordComponent),
    },
    {
        path: 'producer-otp',
        title: 'Send Verification Code',
        loadComponent: () => import('./send-code/send-code.component').then((c) => c.SendCodeComponent),
    },
    {
        path: 'validate-code',
        title: 'Validate Verification Code',
        loadComponent: () => import('./validate-code/validate-code.component').then((c) => c.ValidateCodeComponent),
    },
    {
        path: 'logout',
        title: 'Logout',
        loadComponent: () => import('./shared/components/logout/logout.component').then((c) => c.LogoutComponent),
    },
    { path: 'error', component: ErrorComponent, title: 'An Error Has Occurred' },
    { path: '**', component: ErrorComponent, title: 'An Error Has Occurred' },
];
