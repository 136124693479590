import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { DynamicRecaptchaLoaderService, EnvironmentService } from '@app-core';
import { AlertComponent, FooterComponent, HeaderComponent } from '@app-shared';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet, RouterLink, RouterLinkActive, FormsModule, HttpClientModule, TranslateModule, HeaderComponent, FooterComponent, AlertComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    title = 'Producer Login Application';
    brand: string = '';
    logo: string = '';
    favIcon: HTMLLinkElement = document.querySelector('#appIcon') as HTMLLinkElement;

    constructor(
        public environmentService: EnvironmentService,
        public translateService: TranslateService,
        public recaptchaLoader: DynamicRecaptchaLoaderService,
        public spinner: NgxSpinnerService,
        private router: Router,
        private cookieService: CookieService,
    ) {
        this.brand = this.environmentService.brand;
        translateService.addLangs(['en', 'fr']);
        translateService.setDefaultLang('en');
        const browserLang = translateService.getBrowserLang();
        translateService.use(browserLang?.match(/en|fr/) ? browserLang : 'en');
    }

    ngOnInit(): void {}

    changeOfRoutes() {
        this.router.events.subscribe((event) => {
            const loginRoute: boolean = this.router.url.startsWith('/login');
            if (!loginRoute) {
                this.cookieService.set('is-logon-attempted', 'false', undefined, '/', undefined, true, 'Strict');                
            }            
        });
    }
}
