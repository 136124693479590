<div class="producer-logon-application {{brand}}">
    <app-header></app-header>
    <main>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-8 col-lg-6 g-0 card">
                    <app-alert></app-alert>
                    <router-outlet (activate)="changeOfRoutes()" />
                </div>
            </div>
        </div>
    </main>
    <app-footer></app-footer>
</div>