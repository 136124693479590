import { HttpClient, HttpClientModule, provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideZxvbnServiceForPSM } from 'angular-password-strength-meter/zxcvbn';
import { RecaptchaModule } from 'ng-recaptcha';
import { provideNgxMask } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { routes } from './app.routes';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withInMemoryScrolling({scrollPositionRestoration: 'top'})),
        provideHttpClient(),
        importProvidersFrom(HttpClientModule),
        importProvidersFrom(BrowserAnimationsModule),
        // Removing the ErrorInterceptorService from the providers array in app.config.ts
        // Due to the nature of the application and French translations, errors have to be
        // handled in the components themselves.
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: ErrorInterceptorService,
        //     multi: true,
        // },
        importProvidersFrom(
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient],
                },
            }),
        ),
        importProvidersFrom(RecaptchaModule),
        importProvidersFrom(NgxSpinnerModule.forRoot({ type: 'ball-clip-rotate' })),
        provideZxvbnServiceForPSM(),
        provideNgxMask(),
    ],
};
