<app-spinner></app-spinner>

<form id="loginForm" class="form logon-form" [formGroup]="logonForm" method="POST" action="{{postURL}}" (ngSubmit)="onSubmit($event)">
    <div class="title form-title">{{ 'headings.welcome' | translate }}</div>
    <!-- Username Group -->
    <div class="form-group">
        <label for="username">{{ 'common.username' | translate }}</label>
        <input type="text" class="form-control" id="username" formControlName="username" (change)="onChangeUserName()" autocomplete="username" name="USER">
        <app-field-error [control]="form['username']" [fieldName]="'inputs.username'"></app-field-error>
        <!-- @if (isFieldInError('username')) {
        
        } @else {
        <div class="tip username-tip">
            <small class="text-muted">{{ 'captions.username-tip' | translate }}</small>
        </div>
        } -->

    </div>

    <!-- Password Group -->
    <div class="form-group password-group" (appCapsLock)="capsOn=$event">
        <div class="forgot-password-link">
            <a class="router-link" routerLink="/forgot-password" skipLocationChange="true">{{ 'common.forgot-password' | translate}}</a>
        </div>
        <label for="password">{{ 'common.password' | translate }}</label>
        <input type="password" class="form-control password-control" id="password" formControlName="password" appShowPassword [addClass]="'registration-form'"
            autocomplete="current-password" name="PASSWORD">
        @if (capsOn) {
        <div><small class="caps-lock-warning">{{ 'common.caps-lock-on' | translate }}</small></div>
        } @else {
        @if (isFieldInError('password')) {
        <app-field-error [control]="form['password']" [fieldName]="'inputs.password'"></app-field-error>
        } @else {
        <div><small class="text-muted">{{ 'captions.password-case-sensitive' | translate }}</small></div>
        }
        }
    </div>

    <!-- Save Username Row -->
    <div class="form-group form-check">
        <input type="checkbox" id="saveUsername" formControlName="saveUsername" class="form-check-input" (change)="onChangeUserName()">
        <label for="saveUsername" class="form-check-label">{{ 'common.save-username' | translate }}</label>
    </div>

    <!-- Log In button row -->
    <div class="button-row">
        <input type="hidden" name="TARGET" value="{{ smTarget }}" autocomplete="off">
        <button class="btn btn-primary btn-lg" type="submit" [disabled]="logonForm.invalid" [class.btn--loading]="isLoading">
            <span class="btn-text">{{ 'common.login' | translate }}</span>
        </button>
    </div>
    <div class="row become-a-producer">
        <p> {{ 'Interested in becoming an Intact Producer?' | translate }}
            <a class="a-1" href="https://www.intactspecialty.com/en/marketing/become-a-producer.page?" target="_blank" tabindex="3">{{'&nbsp;Apply today.' | translate
                }}</a>
        </p>
    </div>



    <!-- Help row -->
    @if (brand === 'intactspecialty') {
    <div class="help-row">
        <h4 class="title">{{ 'headings.need-help' | translate }}</h4>
        <p [innerHTML]="'help.producer-service' | translate"></p>
    </div>
    } @else if (brand === 'nmu') {
    <div class="help-row">
        <h4 class="title">{{ 'headings.need-help' | translate }}</h4>
        <p [innerHTML]="'help.producer-service-nmu' | translate"></p>
    </div>
    } @else if (brand === 'bondclick') {
    <div class="help-row">
        <h4 class="title">{{ 'headings.need-help' | translate }}</h4>
        <p [innerHTML]="'help.producer-service-bondclick-canada' | translate"></p>
    </div>
    }
</form>