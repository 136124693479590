import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AlertService, CommonService, EnvironmentService, GlobalConstants, ProducerService } from '@app-core';
import { environment } from '@app-env';
import { AlertComponent, CapsLockDirective, FieldErrorComponent, ShowFieldErrorDirective, ShowPasswordDirective, SpinnerComponent } from '@app-shared';
import { TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OptStart } from '@app-models';

@Component({
    selector: 'app-producer-logon',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        RouterLink,
        RouterLinkActive,
        TranslateModule,
        ShowFieldErrorDirective,
        CapsLockDirective,
        ShowPasswordDirective,
        SpinnerComponent,
        AlertComponent,
        FieldErrorComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: './producer-logon.component.html',
    styleUrl: './producer-logon.component.scss',
})
export class ProducerLogonComponent implements OnInit {
    capsOn: any;
    postURL: string = environment.loginURL;
    brand: string = '';
    smUserName: string = '';
    smAgentName: string = '';
    smTarget: string = '';
    smAuthReason: number = 0;
    logonForm: FormGroup;
    isLoading = false;
    logonAttempted = 'false';

    constructor(
        private commonService: CommonService,
        private cookieService: CookieService,
        private environmentService: EnvironmentService,
        private alertService: AlertService,
        private producerService: ProducerService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
    ) {
        this.brand = this.environmentService.brand;
        this.logonForm = new FormGroup({
            username: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required]),
            saveUsername: new FormControl(false),
        });
    }

    get form() {
        return this.logonForm.controls;
    }

    ngOnInit(): void {
        this.spinnerService.hide();
        const savedUsername = this.cookieService.get('is-username');
        if (savedUsername) {
            this.logonForm.controls['username'].setValue(savedUsername);
            this.logonForm.controls['saveUsername'].setValue(true);
        }

        // Read the Siteminder agent name and target from the query string
        this.smUserName = decodeURIComponent(this.commonService.cleanTargetParam(this.commonService.getQueryString('USERNAME')));
        this.smAuthReason = parseInt(decodeURIComponent(this.commonService.getQueryString('SMAUTHREASON')));        
        this.smAgentName = decodeURIComponent(this.commonService.cleanTargetParam(this.commonService.getQueryString('SMAGENTNAME')));
        this.smTarget = decodeURIComponent(this.commonService.cleanTargetParam(this.commonService.getQueryString('TARGET')));
        this.cookieService.set('is-smtarget', this.smTarget, undefined, '/', undefined, true, 'Strict');
        this.logonAttempted = this.cookieService.get('is-logon-attempted');
        this.alertService.clearAlerts();
        this.verifyLogonCapable();
    }

    clearAlerts(id: string) {
        this.alertService.clearAlerts(id);
    }

    /**
     * Verify if the user is capable of logging in.
     * SMAUTHREASON returns the reason why the user is not able to log in
     */
    verifyLogonCapable(): void {
        let isRefererSiteminder: boolean = this.environmentService.referrer.includes('/Account/ProducerLogin/login');

        if (this.smAuthReason === GlobalConstants.smAuthReasons['Excessive Logon Attempts']) {
            this.alertService.error('errors.account-temp-locked', { heading: 'headings.account-temp-locked' });
        } else if (this.smAuthReason === GlobalConstants.smAuthReasons['Password Change Required']) {
            if (this.environmentService.referrer !== '' && this.smUserName !== '') {
                this.router.navigate(['/change-password', this.smUserName]);
            } else {
                this.alertService.warn('Password change required but username attribute is not set.', { heading: 'Password Change Required' });
            }
        } else if (this.smAuthReason === GlobalConstants.smAuthReasons['User ID Disabled']) {
            this.alertService.error('errors.account-disabled', { heading: 'headings.account-disabled' });
        } else if (isRefererSiteminder && this.logonAttempted === 'true') {
            this.alertService.error('errors.failed-login', { heading: 'headings.failed-login' });
        }
    }

    onChangeUserName(): void {
        const username = this.logonForm.get('username')?.value;
        const saveUsername = this.logonForm.get('saveUsername')?.value;
        if (saveUsername) {
            this.cookieService.set('is-username', username, undefined, '/', undefined, true, 'Strict');
            this.logonForm.controls['saveUsername'].setValue(true);
        } else {
            this.cookieService.delete('is-username', '/');
            this.logonForm.controls['saveUsername'].setValue(false);
        }
    }

    isFieldInError(fieldName: string) {
        let fieldcontrols = this.logonForm.controls[fieldName];
        return fieldcontrols?.errors! && (fieldcontrols?.dirty || fieldcontrols?.touched);
    }

    onSubmit(event: any): void {
        event.preventDefault();
        this.isLoading = true;
        this.alertService.clearAlerts();
        this.spinnerService.show();

        const username = this.logonForm.get('username')?.value;
        this.cookieService.set('is-logon-attempted', 'true', undefined, '/', undefined, true, 'Strict');

        let optStart = new OptStart();
        optStart.alias = username;

        this.producerService.otpStart(optStart).subscribe({
            next: (response) => {
                this.isLoading = false;
                event.target.submit();
            },
            error: (error) => {
                this.alertService.error(error.message, { heading: 'An error has occurred' });
                this.isLoading = false;
                this.spinnerService.hide();
                window.scrollTo(0, 0);
            },
        });
    }
}
